<template>
  <div>
    <div class="p_title">
      <el-button size="small" type="primary" icon="el-icon-plus" @click="handleAdd()">上传</el-button>
      <el-button size="small" type="primary" icon="el-icon-delete" @click="handleDel()">批量删除</el-button>
      <el-button size="small" type="primary" icon="el-icon-refresh-right" @click="getList()">刷新</el-button>
    </div>

    <div class="line"></div>
    <div class="tableBlock">
      <el-table
          :data="tableData"
          style="width: 100%;margin-top:24px"
          :height="tableHeight"
          v-tableHeight="{ bottomOffset: 74 }"
          :tableLoading="loading"
          @selection-change="handleSelectionChange"
      >
        <el-table-column
            type="selection"
            width="55">
        </el-table-column>
        <el-table-column label="文件名称" prop="name">
          <div slot-scope="scope">
            <el-button type="text" @click="handle_open(scope.row.url)">{{ scope.row.name }}</el-button>
          </div>
        </el-table-column>
        <el-table-column label="文件类型" prop="name">
          <div slot-scope="scope">
            {{ scope.row.file_type | file_type_filter }}
          </div>
        </el-table-column>
        <el-table-column label="文件格式" prop="format">
          <div slot-scope="scope">
            {{ scope.row.format | textFormat }}
          </div>
        </el-table-column>
        <el-table-column label="文件大小" prop="size">
          <div slot-scope="scope">
            <div v-if="scope.row.size"> {{ scope.row.size | textFormat }}KB</div>
            <div v-else>-</div>
          </div>
        </el-table-column>
        <el-table-column label="创建日期" prop="created_time">
          <div slot-scope="scope">
            {{ scope.row.created_time | secondFormat }}
          </div>
        </el-table-column>
        <el-table-column label="备注" prop="remarks" :show-overflow-tooltip="true">
          <div slot-scope="scope">
            {{ scope.row.remarks | textFormat }}
          </div>
        </el-table-column>
      </el-table>
    </div>
    <div class="paginationBlock">
      <el-pagination
          @current-change="handleChange"
          :page-size="10"
          layout="total, prev, pager, next"
          :total="total"
      >
      </el-pagination>
    </div>
    <el-dialog title="文件上传" :visible.sync="dialog" width="700px">
      <uploadDialog @handleClose="handleCloseDialog" v-if="dialog"></uploadDialog>
    </el-dialog>
  </div>
</template>
<script>
import uploadDialog from "~bac/components/dealManagement/dataBank/uploadDialog"
export default {
  name: "index",
  components: { uploadDialog },
  data() {
    return {
      loading: false,
      total: null,
      currentPage: 1,
      tableHeight: 100,
      tableData: [],
      dialog: false,
      multipleSelection: []
    }
  },
  filters: {
    file_type_filter(val) {
      let str = '-'
      if (val == 1) {
        str = '海运委托书'
      }
      if (val == 2) {
        str = 'MSDS'
      }
      if (val == 3) {
        str = '危包证'
      }
      if (val == 4) {
        str = '商检报告'
      }
      if (val == 5) {
        str = '其他'
      }
      return str
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    handleChange(e) {
      this.currentPage = e;
      this.getList();
    },
    async getList() {
      this.loading = true
      let params = {
        user_id: this.USER_INFO.id,
        limit: 10,
        start: this.currentPage - 1,
        nopage: 0,
      }
      let result = await this.$store.dispatch("API_bookingSpace/getBookingAttachmentList", params);
      this.loading = false
      if (result && result.success) {
        this.tableData = result.data
        this.total = result.count
      }
    },
    //删除
    async handleDel() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('请选择数据')
      }
      let params = {
        attachment_id: this.multipleSelection.map(item => item.id).join(',')
      }
      let res = await this.$store.dispatch("baseConsole/updateDataBank", params)
      if (res.success) {
        this.$message.success('操作成功')
        this.getList()
      }
    },
    handleAdd() {
      this.dialog = true
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handle_open(url) {
      window.open(url, "", "", false)
    },
    handleCloseDialog() {
      this.dialog = false
      this.getList()
    }
  }
}
</script>
<style scoped lang="less">
/deep/ .el-dialog__footer {
  border-top: 1px solid #F2F2F3;
}

/deep/ .dialog-footer {
  margin-top: 0;

}

.line {
  width: 100%;
  height: 1px;
  background: #C0C4CC;
}

.contentBox, .contentBox_2 {
  .lable {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }

  .input_p {
    width: 322px;
    height: 35px;
    line-height: 35px;
    padding: 0 5px;
    background: #FFFFFF;
    border-radius: 4px;
    border: 1px solid #DCDFE6;
  }

}

.contentBox > div:first-child {
  display: flex;
  align-items: center;

  p {
    width: 300px;
    border: 1px solid #333;
    margin-right: 20px;
  }
}

.contentBox > .type1 {
  margin-top: 16px;
  padding-left: 72px;
  display: flex;

  span {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }

  span:first-child {
    padding-right: 20px;
  }
}

.contentBox > .type3 {
  margin-top: 16px;
  display: flex;

  span {
    padding-top: 10px;
  }

  img {
    width: 120px;
    height: 120px;
  }
}

.contentBox_2 {
  margin-bottom: 20px;

  .j_name {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
    margin-bottom: 16px;
  }
}

.contentBox_2 > div:nth-child(2) {
  display: flex;
  align-items: center;

  span {
    padding-right: 16px;
  }

  div {
    padding-left: 16px;
  }
}

.contentBox_2 > div:nth-child(3) {
  padding-top: 16px;
  margin-bottom: 30px;
}

.dialog_link_box {
  height: 400px;
  overflow: scroll;
}

.tit {
  display: flex;
  align-items: center;

  img {
    width: 37px;
    height: 37px;
  }

  div {
    padding-left: 8px;
  }

  p {
    height: 20px;
    line-height: 20px;
    font-size: 14px;
    font-weight: 800;
    color: rgba(0, 0, 0, 0.85);
  }

  span {
    height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-weight: 400;
    color: #909399;
  }
}

.states_box > div {
  display: flex;
  align-items: center;

  div {
    width: 6px;
    height: 6px;
    background: #BFBFBF;
    border-radius: 3px;
    display: inline-block;
    margin-right: 8px;
  }

  span {
    height: 20px;
    font-size: 14px;
    font-weight: 400;
    color: #415058;
    line-height: 20px;
  }
}

.p_title {
  display: flex;
  align-items: center;
  padding: 0 20px;
  height: 60px;
}
</style>