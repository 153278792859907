<template>
  <div class="form_class">
    <fileUpload
        ref="uploadComponents"
        @selectFile="uploadFile"
        @uploadCompleteOSS="uploadCompleteOSS"
    >
      <el-button type="primary" class="upBtn">本地上传</el-button>
    </fileUpload>
    <el-form
        label-width="100px"
        ref="form"
        :model="form"
        :rules="rules"
        label-position="left"
    >
      <el-form-item label="文件名" prop="name">
        <el-input placeholder="请输入文件名" v-model="form.name" class="input_class">
        </el-input>
      </el-form-item>
      <el-form-item label="文件类型" prop="file_type">
        <el-select v-model="form.file_type" placeholder="请选择文件类型" class="input_class">
          <el-option
              v-for="item in file_type_list"
              :key="item.value"
              :label="item.label"
              :value="item.value"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="文件格式" prop="format">
        {{ form.format ?  form.format : '待上传' }}
      </el-form-item>
      <el-form-item label="文件大小" prop="size">
        {{ form.size ?  (form.size/1024).toFixed(2) + 'KB' : '待上传' }}
      </el-form-item>
      <el-form-item label="备注" prop="remarks">
        <el-input
            class="input_class"
            type="textarea"
            placeholder="请输入备注"
            rows="4"
            v-model="form.remarks"
            maxlength="256"
        ></el-input>
      </el-form-item>
    </el-form>
    <div class="btns">
      <el-button size="small" @click="handleClose">取消</el-button>
      <el-button type="primary" class="submitBtn" size="small" @click="handleSubmit" :loading="loading"> 确定</el-button>
    </div>
  </div>
</template>

<script>
import fileUpload from "@/baseComponents/fileUpload";
import {getFileType} from "@/baseUtils";
export default {
  name: "uploadDialog",
  components: { fileUpload },
  data() {
    return {
      form: {
        name: '',
        format: '',
        size: '',
        url: '',
        remarks: '',
        file_type: ''
      },
      rules: {
        name: [{ required: true, message: " ", trigger: "change" }],
        file_type: [{ required: true, message: " ", trigger: "change" }],
      },
      file_type_list: [
        {
          label: '海运委托书',
          value: '1'
        },
        {
          label: 'MSDS',
          value: '2'
        },
        {
          label: '危包证',
          value: '3'
        },
        {
          label: '商检报告',
          value: '4'
        },
        {
          label: '其他',
          value: '5'
        }
      ],
      loading: false
    }
  },
  methods: {
    uploadFile(val) {
      let obj = {
        name: val[0].name,
        size: val[0].size,
        format: getFileType(val[0].name),
      }
      this.form = Object.assign({}, obj)
      this.$refs.uploadComponents.uploadFileToOss()
    },
    uploadCompleteOSS(val) {
      this.form.url = val.url
    },
    handleClose() {
      this.$emit('handleClose')
    },
    async handleSubmit() {
      if (this.form.url =='') {
        return this.$message.warning('请上传文件')
      }
      this.$refs["form"].validate(async (valid) => {
        if (valid) {
          let parmas = {
            user_id: this.USER_INFO.id,
            file_type: this.form.file_type,
            name: this.form.name,
            format: this.form.format,
            size: (this.form.size/1024).toFixed(2),
            url: this.form.url,
            remarks: this.form.remarks,
          }
          this.loading = true
          let res = await this.$store.dispatch("baseConsole/saveDataBank", parmas);

          if (res.success) {
            this.loading = false
            this.$message.success('操作成功')
            this.handleClose()
          }
        }
      });
    }
  }
}
</script>

<style scoped lang="less">
.form_class {
  padding: 0 20px 20px;
}
.input_class {
  width: 500px;
}
.upBtn {
  margin-bottom: 20px;
}
.btns {
  margin-top: 10px;
  text-align: right;
}
</style>